<template>
  <div class="success" v-if="dealer != null && Object.keys(dealer).length > 0">
    <Header />
    <div class="container">
      <p class="title">Grattis, du har nu bokat en provkörning av</p>

      <p class="carTitle">RENAULT SCENIC E-TECH 100% ELECTRIC</p>
      <div class="imgContainer">
        <img src="@/assets/Scenic-success.jpg" alt="" />
      </div>
      <div class="infoCard">
        <div class="dealerIcon">
          <img src="@/assets/icons/dealerArrow.svg" alt="" />
        </div>
        <p class="dealerName">{{ dealer.name }}</p>
        <div class="dealerAddress">
          <p>{{ dealer.addressHeader }}</p>
          <p>{{ dealer.addressBody }}</p>
        </div>

        <div class="dealerDate">
          <div class="date">
            <p>{{ dealer.date }}</p>
            <p>{{ time }}</p>
          </div>
          <p class="day">{{ getDayFromDate(dealer.date) }}</p>
        </div>
      </div>
      <p class="text">
        Du kommer även att få en bekräftelse via SMS och en påminnelse en dag innan din bokade provkörning. Glöm inte
        att ta med körkort.
      </p>
      <p class="subTitle">Vi ser fram emot ditt besök!</p>
    </div>
    <footer>
      <img src="@/assets/additional_logo.png" />
    </footer>
  </div>
</template>

<script>
import Header from '@/components/Header';
export default {
  name: 'Success',
  data() {
    return {};
  },
  props: ['dealer', 'time'],
  components: {
    Header,
  },
  methods: {
    getDayFromDate(date) {
      let month = date.substring(3, 5);
      let day = date.substring(0, 2);
      let dayOfWeek = new Date(month + '/' + day + '/' + '2024').getDay();
      switch (dayOfWeek) {
        case 0:
          return 'Söndag';
        case 1:
          return 'Måndag';
        case 2:
          return 'Tisdag';
        case 3:
          return 'Onsdag';
        case 4:
          return 'Torsdag';
        case 5:
          return 'Fredag';
        case 6:
          return 'Lördag';
      }
    },
  },
};
</script>
