<template>
  <div class="index">
    <Header />
    <div class="indexContainer">
      <Success v-show="successOpened" :dealer="selectedDealer" :time="selectedTime" />
      <div class="buttonFooter" v-if="timeButton">
        <div class="buttonContainer" @click="goToSwiper">
          <p>Boka provkörning</p>
        </div>
      </div>
      <div class="noTimeContainer" v-if="noTime">
        <div class="noTime" v-click-outside="closeModal">
          <div class="closeContainer">
            <img src="@/assets/icons/close.svg" alt="" @click="closeModal" />
          </div>
          <div class="data">
            <div class="block"></div>
            <div class="noTimeInfo">
              <p class="dealerTitle">{{ selectedDealer.name }}</p>
              <div class="dealerAddress">
                <p>{{ selectedDealer.addressHeader }}</p>
                <p>{{ selectedDealer.addressBody }}</p>
              </div>
              <div class="dealerDate">
                <div class="date">
                  <p>{{ selectedDealer.date }}</p>
                  <p>{{ form.start }}</p>
                </div>
                <p class="day">{{ getDayFromDate(selectedDealer.fullDate) }}</p>
              </div>

              <p class="titleNoTime">Tyvärr har din önskade tid precis bokats av någon annan.</p>
              <p class="descprtionNoTime">Vänligen välj en annan tid.</p>
              <div class="actionButton" @click="goBackToTime">
                <p>Välj tid</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="welcomeContainer">
        <div class="info">
          <p class="mainTitle">
            RENAULT SCENIC <br />
            E-TECH 100% <br />
            ELECTRIC
          </p>
          <p class="subTitle">vi har ändrat allt, utom namnet</p>
          <p class="text" v-if="!hasAnID">
            Ett unikt tillfälle att vara bland de första att uppleva Renaults nya elektriska familjebil, Scenic E-Tech
            100% electric. Välkommen att boka din tid.
          </p>
          <div class="dealerBox" v-else>
            <div class="block"></div>
            <div class="allInfo">
              <p class="dealerTitle">{{ selectedDealer.name }}</p>
              <div class="dealerAddress">
                <p>{{ selectedDealer.addressHeader }}</p>
                <p>{{ selectedDealer.addressBody }}</p>
              </div>
              <p class="date">{{ selectedDealer.date }}</p>
              <p class="day">{{ getDayFromDate(selectedDealer.fullDate) }}</p>
            </div>
          </div>
          <div class="actionButton" @click="goToSwiper">
            <p>Boka provkörning</p>
          </div>
        </div>
        <div class="photo">
          <img src="@/assets/Scenic.jpg" alt="" />
          <div class="logo-icon">
            <img src="@/assets/additional_logo.png" />
          </div>
        </div>
      </div>

      <div class="swiperContainer" id="swiper" v-if="!hasAnID">
        <div class="titleContainer">
          <p class="steps">Steg 1/3</p>
          <p class="title">Välj plats</p>
        </div>
        <div class="swiper activateYScroll" id="swiperContainer" ref="cardScrollWrapper">
          <div class="cardContainer activateYScroll" id="cardContainer" ref="cardScrollContent">
            <div
              class="card"
              v-for="(dealer, id) in dealers"
              :key="id"
              :class="{ active: selectedDealer.id === dealer.id }">
              <p class="cityLocation">{{ dealer.city }}</p>
              <p class="dealer">{{ dealer.name }}</p>
              <div class="address">
                <p>{{ dealer.addressHeader }}</p>
                <p>{{ dealer.addressBody }}</p>
              </div>
              <div class="dateBox">
                <div class="timeBox">
                  <p class="date">{{ dealer.date }}</p>
                  <p class="day">{{ getDayFromDate(dealer.fullDate) }}</p>
                </div>

                <div class="selectedDealer" v-if="selectedDealer.id === dealer.id">
                  <img src="@/assets/icons/checked.svg" alt="" />
                </div>
                <div class="selectButton" @click="selectDealer(dealer)" v-else>
                  <p>Välj</p>
                </div>
              </div>
            </div>
          </div>

          <div id="scroll" ref="scrollBar" class="activateYScroll">
            <span id="line"></span>
            <img id="anchor" src="@/assets/icons/scroll.svg" alt="" ref="scrollAnchor" />
          </div>
        </div>
      </div>
      <div class="noSlotsContainer" v-if="noSlots" id="time">
        <div class="noslots">
          <div class="block"></div>
          <div class="info">
            <p class="titleInfo">Tyvärr är alla tider för provkörning bokade.</p>
            <p class="descriptionInfo">Kontakta din återförsäljare för mer information.</p>
          </div>
        </div>
      </div>
      <div class="timeContainer" id="time" v-if="!noSlots" :class="{ notAveabile: isDealerSelected === false }">
        <div class="time">
          <div class="titleContainer">
            <p class="steps" v-if="!hasAnID">Steg 2/3</p>
            <p class="title" :class="{ invalid: $v.form.start.$error }">
              Välj tid
              <span v-if="$v.form.start.$error">- Obligatoriskt</span>
            </p>
          </div>
          <div class="timeTable">
            <div class="timeRow first">
              <template v-for="(time, index) in times">
                <div
                  class="timeCard"
                  :key="index"
                  @click="setTime(time)"
                  :class="{
                    active: form.start == time,
                    disabled: !isTimeAvailable(time),
                  }">
                  <p>{{ time }}</p>
                </div>
              </template>
            </div>
            <div class="timeRow">
              <template v-for="(time, index) in times2">
                <div
                  class="timeCard"
                  :key="index"
                  @click="setTime(time)"
                  :class="{
                    active: form.start == time,
                    disabled: !isTimeAvailable(time),
                  }">
                  <p>{{ time }}</p>
                </div>
              </template>
            </div>
          </div>
        </div>
      </div>

      <div class="contactContainer" :class="{ notAveabile: isTimeSelected === false }" id="contact">
        <div class="contact">
          <div class="titleContainer">
            <p class="steps" v-if="!hasAnID">Steg 3/3</p>
            <p class="title">Kontaktuppgifter</p>
          </div>
          <div class="contactForm">
            <div class="inputRow">
              <div class="inputGroup" :class="{ invalid: $v.form.first_name.$error }">
                <input
                  type="text"
                  v-model="form.first_name"
                  id="name"
                  :disabled="isTimeSelected === false"
                  placeholder="Name" />
                <label for="name"
                  >Förnamn
                  <span v-if="$v.form.last_name.$error">- Obligatoriskt</span>
                </label>
              </div>
              <div class="inputGroup" :class="{ invalid: $v.form.last_name.$error }">
                <input
                  type="text"
                  v-model="form.last_name"
                  id="surname"
                  :disabled="isTimeSelected === false"
                  placeholder="Surname" />
                <label for="surname"
                  >Efternamn

                  <span v-if="$v.form.last_name.$error">- Obligatoriskt</span></label
                >
              </div>
            </div>
            <div class="inputRow">
              <div class="inputGroup" :class="{ invalid: $v.form.email.$error }">
                <input
                  type="text"
                  v-model="form.email"
                  id="email"
                  :disabled="isTimeSelected === false"
                  placeholder="Email" />
                <label for="email"
                  >E-post

                  <span v-if="$v.form.email.$error">- Obligatoriskt</span>
                </label>
              </div>
              <div class="inputGroup" :class="{ invalid: $v.form.phone.$error }">
                <input
                  type="text"
                  v-model="form.phone"
                  id="phone"
                  :disabled="isTimeSelected === false"
                  placeholder=" " />
                <label for="phone"
                  >Mobil
                  <span v-if="$v.form.phone.$error">
                    <span v-if="form.phone.length == 0">- Obligatoriskt</span>
                    <span v-else>- Fel format</span>
                  </span>
                </label>
              </div>
            </div>
            <p class="legal-text">
              För att genomföra provkörning behöver du vara över 18 år och ha ett giltigt körkort.
            </p>
            <p class="subTitle">
              Genom att klicka på Skicka, godkänner du att RN Nordic AB och din valda återförsäljare använder dina
              kontaktuppgifter för att kunna genomföra provkörningen.
            </p>
            <div class="aggrement" :class="{ invalid: $v.form.aggrement.$error }">
              <p>
                Vill du att få nyhetsbrev från oss?
                <span v-if="$v.form.aggrement.$error">- Obligatoriskt</span>
              </p>
              <label class="radioButton">
                <input type="radio" id="ja" value="1" :disabled="isTimeSelected === false" v-model="form.aggrement" />
                <i></i>
                <span>Ja</span>
              </label>

              <label class="radioButton">
                <input type="radio" id="nej" value="0" :disabled="isTimeSelected === false" v-model="form.aggrement" />
                <i></i>
                <span>Nej</span>
              </label>
            </div>
            <div class="text">
              <p>
                Dina personuppgifter behandlas av RN Nordic AB och medlemmarna i det auktoriserade
                återförsäljarnätverket som oberoende personuppgiftsansvariga. RN Nordic AB samlar in och behandlar dina
                kontaktuppgifter i syfte att överföra dem till av dig vald återförsäljare och, om du har samtyckt till,
                skicka dig vårt nyhetsbrev. Dina personuppgifter kommer att behandlas av en säljare hos den
                återförsäljare du har valt för att kontakta dig angående provkörningen du har bett om.
              </p>
              <p>
                Du har rätt att begära att få tillgång till dina personuppgifter. Du kan även ha rätt att begära
                rättelse av dina personuppgifter, eller att få dem raderade. Du kan invända mot behandling av dina
                personuppgifter, utan att ange dina skäl för detta, när det gäller invändning mot att motta
                direktmarknadsföring, och baserat på skäl som relaterar till din specifika situation i andra fall än
                invändningar mot direktmarknadsföring. Du kan även begära begränsning av behandling av dina
                personuppgifter. Du kan be om att få ut en kopia av dina personuppgifter i ett strukturerat
                standardformat.
              </p>
              <p>
                Du även har rätt att lämna in ett klagomål till Integritetskyddsmyndigheten (IMY). Mer information finns
                på
                <a href="http://www.imy.se/"> imy.se</a>.
              </p>
              <p>
                För att utöva någon av dessa rättigheter kan du, när din identitet har säkerställts, kontakta oss via
                e-post på
                <a href="mailto:privacy.nordic@renault.com">privacy.nordic@renault.com</a>
                , via post på Box 1028, 164 21, Kista eller genom att fylla i formuläret
                <a href="https://www.renault.se/kontakt/kundservice.html">här</a>
                . För mer information, vänligen se vår
                <a href="https://www.renault.se/integritetspolicy.html">integritetspolicy</a>
                .
              </p>
            </div>
            <div class="buttonContainer">
              <button class="actionButton" @click="submit" :disabled="isTimeSelected === false">
                <p>Skicka</p>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <footer>
      <img src="@/assets/additional_logo.png" />
    </footer>
  </div>
</template>

<script>
import axios from 'axios';
import Header from '@/components/Header';
import Success from '@/components/Success';
import { required, email } from 'vuelidate/lib/validators';
import { validationMixin } from 'vuelidate';
import Scroller from '@/components/Scroller';
import DateFormatter from '../dateFormatter';

const initialFormData = {
  first_name: '',
  last_name: '',
  phone: '',
  email: '',
  start: '',
  aggrement: '',
  comment: null,
  bus: null,
  per_day: 20,
  duration: 30,
  days: 2,
};

export default {
  name: 'Index',
  mixins: [validationMixin],

  data() {
    return {
      form: Object.assign({}, initialFormData),
      slots: [],
      times: {
        0: '10:00',
        1: '11:00',
        2: '12:00',
        3: '13:00',
        4: '14:00',
        5: '15:00',
        6: '16:00',
        7: '17:00',
        8: '18:00',
        9: '19:00',
      },
      times2: {
        0: '10:30',
        1: '11:30',
        2: '12:30',
        3: '13:30',
        4: '14:30',
        5: '15:30',
        6: '16:30',
        7: '17:30',
        8: '18:30',
        9: '19:30',
      },
      successOpened: false,
      selectedTime: null,
      timeButton: true,
      selectedDealer: {},
      isTimeSelected: false,
      isDealerSelected: false,
      hasAnID: false,
      noSlots: false,
      DateFormatter: DateFormatter,
      noTime: false,
    };
  },
  validations: {
    form: {
      first_name: {
        required,
      },
      last_name: {
        required,
      },
      phone: {
        required,
        validateNumber: (value) => {
          let newValue = value.replace(/[^0-9]/g, '');
          if (newValue.substring(0, 2) === '00') {
            newValue = newValue.substring(2);
          }
          if (newValue.length == 11 && newValue.substring(0, 2) === '46') {
            return true;
          } else if (newValue.length == 10) {
            return true;
          } else {
            return false;
          }
        },
      },
      email: {
        required,
        email,
      },
      aggrement: {
        required,
      },
      start: {
        required,
      },
    },
  },
  components: {
    Header,
    Success,
  },
  methods: {
    goBackToTime() {
      this.closeModal();
      this.slots = [];
      this.form.start = '';
      this.fetchData(this.selectedDealer);
      this.goToTime();
    },
    selectDealer(dealer) {
      this.selectedDealer = dealer;
      this.slots = [];
      this.form = Object.assign({}, initialFormData);
      this.isDealerSelected = true;
      this.fetchData(this.selectedDealer);
    },
    goToSwiper() {
      let time;
      if (this.hasAnID) {
        time = document.getElementById('time');
      } else {
        time = document.getElementById('swiper');
      }
      time.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      });
      this.timeButton = false;
    },
    goToTime() {
      let time = document.getElementById('time');
      time.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      });

      this.timeButton = false;
    },
    goToContact() {
      let time = document.getElementById('contact');
      time.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      });
      this.timeButton = false;
    },
    handleScroll() {
      let time;
      if (this.hasAnID) {
        time = document.getElementById('time');
      } else {
        time = document.getElementById('swiper');
      }
      if (time.getBoundingClientRect().top < 150) {
        this.timeButton = false;
      } else {
        this.timeButton = true;
      }
    },
    setTime(time) {
      if (this.isTimeAvailable(time)) {
        this.form.start = time;
        this.isTimeSelected = true;
        this.goToContact();
      }
    },
    isEmpty(obj) {
      for (var prop in obj) {
        if (Object.prototype.hasOwnProperty.call(obj, prop)) {
          return false;
        }
      }

      return JSON.stringify(obj) === JSON.stringify({});
    },
    isTimeAvailable(time) {
      if (!this.isEmpty(this.selectedDealer)) {
        let dealers = this.dealers;
        for (let dealer in dealers) {
          if (this.selectedDealer.id === dealers[dealer].id) {
            for (let i in this.selectedDealer.disabledTime) {
              if (this.selectedDealer.disabledTime[i] === time) {
                return false;
              }
            }
            break;
          }
        }
      }
      for (let i = 0; i < this.slots.length; i++) {
        if (this.slots[i] === time) {
          return true;
        }
      }

      return false;
    },
    getDayFromDate(date) {
      let dayOfWeek = new Date(date).getDay();
      switch (dayOfWeek) {
        case 0:
          return 'Söndag';
        case 1:
          return 'Måndag';
        case 2:
          return 'Tisdag';
        case 3:
          return 'Onsdag';
        case 4:
          return 'Torsdag';
        case 5:
          return 'Fredag';
        case 6:
          return 'Lördag';
      }
    },

    decreseTime(time, decreaseBy) {
      let hour = time.substring(0, 2);
      let minute = time.substring(3, 5);
      let newHour = parseInt(hour) - decreaseBy;
      return newHour + ':' + minute;
    },

    calculateTimeDifferenceBetweenTimes(time) {
      const timezone = DateFormatter.format(new Date(time), 'Z');
      const difference = parseInt(timezone.substring(1, 3));
      return difference;
    },

    submit() {
      this.$v.$touch();

      if (this.$v.$invalid) {
        if (this.$v.form.start.$error) {
          this.goToTime();
          return;
        } else {
          this.goToContact();
          return;
        }
      } else {
        let initTime = this.form.start;
        let formatedTime = DateFormatter.format(
          new Date(Date.parse(this.selectedDealer.fullDate + 'T' + this.form.start + ':00Z')),
          'YYYY-MM-DDTHH:mm:ssZ'
        );
        let fullTime =
          this.selectedDealer.fullDate +
          'T' +
          this.decreseTime(this.form.start, this.calculateTimeDifferenceBetweenTimes(formatedTime)) +
          ':00Z';
        this.form.start = fullTime;
        if (this.form.aggrement == 1) {
          this.form.comment = 'Newsletter';
        }
        axios
          .post('/api/reserve', this.form)
          .then(() => {
            this.selectedTime = initTime;
            this.openSuccess();
          })
          .catch(() => {
            this.form.start = initTime;
            this.openModal();
          });
      }
    },
    openModal() {
      this.noTime = true;
      document.querySelector('body').style.overflow = 'hidden';
    },
    closeModal() {
      this.noTime = false;
      document.querySelector('body').style.overflow = 'auto';
    },

    openSuccess() {
      document.querySelector('body').style.overflow = 'hidden';
      this.successOpened = true;
    },
    fetchData(dealerSelected) {
      let oneDayLess = new Date(dealerSelected.fullDate);
      oneDayLess.setDate(oneDayLess.getDate() - 1);
      if (!this.hasAnID) {
        this.goToTime();
      }
      axios
        .post('/api/slots', {
          per_day: 28,
          duration: 30,
          days: 1,
          start: oneDayLess.toISOString(),
        })
        .then((response) => {
          if (response.data && response.data.slots) {
            for (let slot in response.data.slots) {
              for (let i = 0; i < response.data.slots[slot].length; i++) {
                let dateFormated = DateFormatter.format(new Date(Date.parse(response.data.slots[slot][i])), 'HH:mm');
                let isDisabled = false;

                for (let j = 0; j < dealerSelected.disabledTime.length; j++) {
                  if (dealerSelected.disabledTime[j] === dateFormated) {
                    isDisabled = true;
                    break;
                  }
                }

                if (!isDisabled) {
                  this.slots.push(dateFormated);
                }
              }
            }
            this.checkIfThereAreTimes();
          }
        })
        .catch((error) => {
          this.slots = {};
          this.noSlots = true;
          console.log(error);
        });
    },
    checkID() {
      if (
        this.$route.params.dealerID == 'b721d86a85d38961' ||
        this.$route.params.dealerID == 'fb4a9349746956e7' ||
        this.$route.params.dealerID == 'e99819acda447054' ||
        this.$route.params.dealerID == 'be472b796334eba1'
      ) {
        return;
      } else if (this.$route.params.dealerID) {
        this.hasAnID = true;
        let dealerId = this.$route.params.dealerID;
        let dealers = this.dealers;
        for (let dealer in dealers) {
          if (dealerId === dealers[dealer].id) {
            this.selectedDealer = dealers[dealer];
          }
        }
        this.isDealerSelected = true;
        this.fetchData(this.selectedDealer);
      }
    },
    checkIfThereAreTimes() {
      for (let i = 0; i < this.slots.length; i++) {
        if (this.checkIfThereIsATime(this.slots[i])) {
          this.noSlots = false;
          return;
        }
      }
      this.noSlots = true;
    },
    checkIfThereIsATime(time) {
      for (let i = 0; i < Object.keys(this.times).length; i++) {
        if (this.times[i] === time || this.times2[i] === time) {
          return true;
        }
      }
      return false;
    },
  },

  mounted() {
    const anchor = this.$refs.scrollAnchor;
    const scrollBar = this.$refs.scrollBar;
    const content = this.$refs.cardScrollContent;
    const wrapper = this.$refs.cardScrollWrapper;
    new Scroller(anchor, scrollBar, content, wrapper);
    this.checkID();
  },
  computed: {
    dealers() {
      let dealers = window.dealers;
      if (
        this.$route.params.dealerID == 'b721d86a85d38961' ||
        this.$route.params.dealerID == 'fb4a9349746956e7' ||
        this.$route.params.dealerID == 'e99819acda447054' ||
        this.$route.params.dealerID == 'be472b796334eba1'
      ) {
        let newDealers = [];
        for (let dealer in dealers) {
          if (this.$route.params.dealerID == 'b721d86a85d38961') {
            if (dealers[dealer].id == 'b721d86a85d38961') {
              newDealers.push(dealers[dealer]);
            }
          } else if (
            this.$route.params.dealerID == 'fb4a9349746956e7' ||
            this.$route.params.dealerID == 'e99819acda447054' ||
            this.$route.params.dealerID == 'be472b796334eba1'
          )
            if (
              dealers[dealer].id == 'fb4a9349746956e7' ||
              dealers[dealer].id == 'e99819acda447054' ||
              dealers[dealer].id == 'be472b796334eba1'
            ) {
              newDealers.push(dealers[dealer]);
            }
        }
        return newDealers;
      } else {
        dealers.sort((a, b) => {
          return new Date(a.fullDate) - new Date(b.fullDate);
        });
        let today = new Date();
        today.setHours(0, 0, 0, 0);
        for (let dealer = 0; dealer < dealers.length; dealer++) {
          let dealerDate = new Date(dealers[dealer].fullDate);
          if (dealerDate < today) {
            dealers.splice(dealer, 1);
            dealer--;
          }
        }
        return dealers;
      }
    },
  },
  created() {
    window.addEventListener('scroll', this.handleScroll);
  },
};
</script>

<style></style>
