<template>
  <div class="header">
    <img src="@/assets/icons/headerLogo.svg" alt="" />
  </div>
</template>

<script>
export default {
  name: "Index",
  data() {
    return {};
  },
};
</script>
