import Hammer from "hammerjs";

export default class Scroller {
    constructor(anchor, scrollBar, content, wrapper) {
        this.anchor = anchor;
        this.scrollBar = scrollBar;
        this.content = content;
        this.wrapper = wrapper;

        this.flag_mouseDown = false;
        this.scrollingEnabled = true;
        this.oldMousePosition = 0;
        //scroll amount 1 = start, 0 = end
        this.oldScrollAmount = 1;
        this.newScrollAmount = 1;
        this.ratio = 1;

        this.content.addEventListener("mousedown", (e) => {
            this.startDrag(e.clientX);
        });
        this.anchor.addEventListener("mousedown", (e) => {
            this.startDrag(e.clientX, -1);
        });
        this.scrollBar.addEventListener("mousedown", (e) => {
            this.moveContent(1 - (e.clientX - this.scrollBar.getBoundingClientRect().left) / this.scrollBar.offsetWidth);
        });
        window.addEventListener("mouseup", (e) => {
            this.endDrag(e.clientX);
        });
        window.addEventListener("mousemove", (e) => {
            this.drag(e.clientX);
        });



        if (!this.isScrollNeeded()) {
            this.scrollBar.classList.add('hidden');
            this.scrollingEnabled = false;
        } else {
            this.scrollBar.classList.remove('hidden');
            this.scrollingEnabled = true;
        }
        window.addEventListener("resize", () => {
            if (!this.isScrollNeeded()) {
                this.scrollBar.classList.add('hidden');
                this.scrollingEnabled = false;
            } else {
                this.scrollBar.classList.remove('hidden');
                this.scrollingEnabled = true;
            }
        });

        let mc = new Hammer.Manager(this.content);
        let pan = new Hammer.Pan();

        mc.add(pan);

        mc.on("panstart", (e) => {
            this.startDrag(e.center.x);
        });
        mc.on("pan", (e) => {
            if (Math.abs(e.velocityX) < Math.abs(e.velocityY)) {
                return;
            }
            this.drag(e.center.x);
        });
        mc.on("panend", (e) => {
            this.endDrag(e.center.x);
        });

        let mcScroll = new Hammer.Manager(this.scrollBar);
        let pressScroll = new Hammer.Press();
        let tapScroll = new Hammer.Tap();
        let panScroll = new Hammer.Pan();
        mcScroll.add(pressScroll);
        mcScroll.add(tapScroll);
        mcScroll.add(panScroll);

        mcScroll.on("press", (e) => {
            this.moveContent(1 - (e.center.x - this.scrollBar.getBoundingClientRect().left) / this.scrollBar.offsetWidth);
        });

        mcScroll.on("panstart", (e) => {
            this.startDrag(e.center.x, -1);
        });
        mcScroll.on("pan", (e) => {
            if (Math.abs(e.velocityX) < Math.abs(e.velocityY)) {
                return;
            }
            this.drag(e.center.x);
        });
        mcScroll.on("panend", (e) => {
            this.endDrag(e.center.x);
        });
    }
    isScrollNeeded() {
        const wrapperWidth = this.wrapper.offsetWidth;
        const contentWidth = this.content.scrollWidth;
        return (wrapperWidth < contentWidth)
    }
    moveContent(newPosition) {
        const wrapperWidth = this.wrapper.offsetWidth;
        const contentWidth = this.content.scrollWidth;
        const anchorWidth = this.anchor.offsetWidth;
        if (newPosition < 0) {
            newPosition = 0;
        } else if (newPosition > 1) {
            newPosition = 1;
        }

        this.anchor.style.left =
            (1 - newPosition) * (1 - anchorWidth / wrapperWidth) * 100 + "%";

        this.content.style.marginLeft =
            -(1 - newPosition) * (contentWidth - wrapperWidth) + "px";
        this.newScrollAmount = newPosition;
    }
    startDrag(x, direction = 1) {
        const contentWidth = this.content.scrollWidth;
        const wrapperWidth = this.wrapper.offsetWidth;
        const scrollBarWidth = this.scrollBar.offsetWidth;
        if (direction == 1) {
            this.ratio = (wrapperWidth / contentWidth) * 2;
        } else {
            this.ratio = -wrapperWidth / scrollBarWidth;
        }
        this.flag_mouseDown = true;
        this.oldMousePosition = x;
        this.oldScrollAmount = this.newScrollAmount;
    }
    endDrag() {
        this.flag_mouseDown = false;
    }
    drag(x) {
        if (!this.scrollingEnabled) {
            return;
        }
        const wrapperWidth = this.wrapper.offsetWidth;
        if (!this.flag_mouseDown) {
            return;
        }
        const distance = (x - this.oldMousePosition) * this.ratio;
        this.moveContent(this.oldScrollAmount + distance / wrapperWidth);
    }
}